import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const banner = {
    title: "同意ログ",
    tagline: "Cookie & SSL Scannerでサイト上のあらゆるクッキーとトラッキング技術をスキャンします"
}

export const features = {
    image: (<StaticImage title='同意ログ' src={"../../static/images/feature/consent-log/featureImage.png"} width={530} quality={100} alt="Consent Log Feature Image"  />),
    contents: [
        {
            title: "ログと同意のトラッキング",
            paragraph: <p>Secure Privacyは、ウェブサイト上の広告やデータ収集用クッキーに対し、ユーザーが許可したり拒否したりした同意内容をリアルタイムに記録できます。クッキーのバナーが表示されて訪問者が同意を表明するとすぐに、法に準拠した形で記録・保存します。ユーザーが同意を撤回した場合は、そのデータは記録から削除されます。このプロセスは完全に自動化されています。何かアクションを起こす必要はありません。</p>
        },
    ]
}

